import './bootstrap.js';
import VueGtag from "vue-gtag";
import { createInertiaApp } from '@inertiajs/vue2'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

const asciiLogo = `
 _   _  _____ __  __ _____    ____  _____  _   _   __  __     _   _____  _   _ 
| \\ | || ____|\\ \\/ /|_   _|  / ___|| ____|| \\ | | |  \\/  |   / \\ |_   _|| | | |
|  \\| ||  _|   \\  /   | |   | |  _ |  _|  |  \\| | | |\\/| |  / _ \\  | |  | |_| |
| |\\  || |___  /  \\   | |   | |_| || |___ | |\\  | | |  | | / ___ \\ | |  |  _  |
|_| \\_||_____|/_/\\_\\  |_|    \\____||_____||_| \\_| |_|  |_|/_/   \\_\\|_|  |_| |_|
																			  
`;

import store from '@nextgenmath/js/store';

import Layout from './pages/Layout.vue';

import { VueReCaptcha } from 'vue-recaptcha-v3'

createInertiaApp({
    resolve: async (name) => {
        if(name.startsWith('@root/')) {
            name = '../../' + name.replace('@root/', '') + '.vue';
        } 
        else {
            name = `./pages/${name}.vue`;
        }

        let page = await resolvePageComponent(name, import.meta.glob(['./pages/**/*.vue', '../../**/**/*.vue']));
        
        page.default.layout = page.default.layout || Layout;
        
        return page;
    },

    setup({ el, App, props, plugin }) {
        Vue.use(plugin)

        Vue.use(VueReCaptcha, { siteKey: '6Ldcqv8UAAAAAEbzubFJhcDs5kooCxPL_2qbdDvs' });

        Vue.use(VueGtag, {
            config: {
              id: "G-RT5HQSMG5G"
            },
              checkDuplicatedScript: true,
          });
          

        new Vue({
            data() {
                return {
                    layout: 'ngm',
                };
            },
            store,
            render: h => h(App, props),
            beforeCreate() {
                this.$online.stop();
                this.$online.removeAllListeners()
            },
            created() {
                console.log(asciiLogo);
            },
        }).$mount(el)
    },
    progress: {
        // The delay after which the progress bar will appear
        // during navigation, in milliseconds.
        delay: 0,
    
        // The color of the progress bar.
        color: '#2878CC',
    
        // Whether to include the default NProgress styles.
        includeCSS: true,
    
        // Whether the NProgress spinner will be shown.
        showSpinner: false,
    },
});

