import { BootstrapVue } from 'bootstrap-vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vuelidate from 'vuelidate';
import * as  rax from 'retry-axios';
import { Head, Link } from '@inertiajs/vue2'
import Vue from 'vue';
import Swal from 'sweetalert2';
import axios from 'axios';
import FloatingVue from 'floating-vue'
import VueLazyload from 'vue-lazyload';

window.Vue = Vue;
window.Swal = Swal;

Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(FloatingVue);
Vue.use(VueLazyload, {
    lazyComponent: true,
});

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('Head', Head);
Vue.component('Link', Link);

FloatingVue.options.themes.tooltip.disabled = window.innerWidth <= 768

window.axios = axios;

window.process = {
    env: {
        NODE_ENV: import.meta.env.MODE,
    },
};

// This breaks our tests otherwise
if (process.env.NODE_ENV != 'test') {
    rax.attach();
    window.axios.raxConfig = {
        default: {
            retry: 3,
            noResponseRetries: 3,
            retryDelay: 1500, // in ms, built-in exponential backoff
            httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT', 'PATCH'], // Had to add patch as it's not in default
                // You can detect when a retry is happening, and figure out how many
                // retry attempts have been made
            onRetryAttempt: (err) => {
                const cfg = rax.getConfig(err);
                console.log(`Retry attempt #${cfg.currentRetryAttempt}`);
            }
        }
    };
    window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    window.axios.defaults.withCredentials = true;    
}

import './FontAwesomeIcons.js';
import '@root/js/nextGenMath.js';
import '@root/js/prototypes.js';
import '@root/js/helpers/functions.ts';
